import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      // NAV
      homenav: "Home",
      skillsnav: "Skills",
      experiencenav: "Experience",
      projectsnav: "Projects",
      contactnav: "Contact",
      docsnav: "Docs",
      // END NAV

      // HOME
      welcome: "Welcome to my portfolio",
      showcase:
        "This is a showcase of my journey, experience, and passion for technology and development. Through this website, I aim to highlight my skills, expertise, and the projects I’ve been proud to work on.",
      whoAmI: "Who Am I?",
      about:
        "I am a dedicated and enthusiastic programmer with <strong>3 years of experience</strong>. With a passion for creating innovative digital solutions, I specialize in <strong>web development</strong>, <strong>backend systems</strong>, and <strong>responsive design</strong>. My goal is to blend creativity and functionality, delivering projects that enhance both <strong>user experience</strong> and technical performance.",
      skills: "My Skills & Expertise",
      skillsList: {
        frontend:
          "Frontend Development: Modern, responsive, and interactive interfaces.",
        backend: "Backend Development: Efficient and secure server-side logic.",
        design:
          "Responsive Design: Seamless user experience across all devices.",
        security:
          "Authentication & Security: User access control and data protection.",
      },
      whatYoullFind: "What You'll Find Here",
      projectsDescription:
        "Explore the projects I’ve worked on—whether it’s <strong>dynamic websites</strong>, <strong>database-driven systems</strong>, or <strong>custom web applications</strong>. I believe in continuous growth and learning, and my portfolio reflects this evolving journey of discovery.",
      viewProjects: "View My Projects",
      // END HOME

      // SKILL
      mySkills: "My Skills",
      skillCategories: {
        "Programming Languages": "Programming Languages",
        "Frontend Development": "Frontend Development",
        "Backend Development": "Backend Development",
        Databases: "Databases",
        "Version Control": "Version Control",
        "API Development": "API Development",
        "Tools & Technologies": "Tools & Technologies",
        "Other Skills": "Other Skills",
        "Mobile Development": "Mobile Development",
      },
      // END SKILL

      // EXPERIENCE
      myExperience: "My Experience",
      experienceTitles: {
        title_exp1: "IT Support at UMKM Cooperative, Central Java",
        title_exp2: "IT Programmer at Macanan Jaya Cemerlang",
        title_exp3: "Bootcamp Software Engineer at Formulatrix Indonesia",

        title_exp4:
          "IT Programmer & Business Operations Excelence at Cikarang Inland Port",
        title_exp5: "Programmer - Self-employed",
      },
      experienceStatuses: {
        internship: "Internship",
        apprentice: "Apprenticeship",
        contract: "Contract",
        fulltime: "Full-time",
        freelance: "Freelance",
      },
      experienceDurations: {
        duration_exp1: "August 2019 - September 2019",
        duration_exp2: "March 2022 - June 2023",
        duration_exp3: "November 2023 - January 2024",
        duration_exp4: "April 2024 - Present",
        duration_exp5: "March 2021 - Present",
      },
      experienceDescriptions: {
        desc_exp1:
          "Collaboration with IT team to build and improve websites, and creating 2D designs.",
        desc_exp2:
          "Collaboration with IT team's to:\n* Developed and maintained various systems (marketing, purchasing, warehouse management, finance) using Visual Basic/VB6 & Crystal Report.\n* Created operational websites using CodeIgniter, Vue.js, and Laravel.\n* Developed and maintained production applications using FoxPro.\n* Managed and maintained database using SQL Server.",
        desc_exp3:
          "Learn an intensive educational programs that focus on C# programming language,Object Oriented Programming, ASP.NET Core 7 framework, loggers, Unit testing and design patterns.",
        desc_exp4:
          "- IT BOE (IT Business Operation Excellence)\nAs an IT BOE, I focus on ensuring seamless business operations by supporting users when issues arise and analyzing both systems and data.\nI work to identify and resolve bottlenecks, optimize workflows, and implement system upgrades to improve efficiency.\n\n- IT Programmer\nAs an IT Programmer, I build and maintain custom websites using CodeIgniter & Sql Server.\nI focus on developing new features, fixing issues, and optimizing systems to ensure smooth operations.\nI manage website solutions for three companies: Cikarang Dryport (CDP), Cikarang Gerbang Solusi (CGS), and Gerbang Teknologi Cikarang (GTC), ensuring they meet specific business needs.\n\nI’ve managed systems like:\n* DOSS CIP & CGS (DryPort One Stop Service System)\n* Warehouse System GTC & CGS (Inventory and Warehouse Management)\n* Autogate System (Automated Gate System for vehicles)\n* MyCDP (Order Management System)\n* Vendor Portal App (Vendor Management Portal)\n\nI’ve also built from scratch:\n* Website Monitoring E-Document Order (Tracking online orders and documents)\n* Website Booking Vehicle (Vehicle booking and scheduling system)\n\nAdditionally, I have collaborated with Bea Cukai to connect and integrate systems, ensuring seamless data exchange.",
        desc_exp5:
          "As a freelance developer, I create custom websites and apps based on client's needs, with a strong focus on intuitive user interfaces and seamless user experiences.",
      },
      // END EXPERIENCE

      // PROJECT
      myProject: "My Projects",
      projects: [
        {
          title: "CV v1",
          description: "My portfolio website version 1",
          image: "cv.jpg",
          btnProject: "Show",
          link: "https://cv1.decodess.com/",
          language: ["Vue.js", "Javascript", "CSS", "Tailwind", "HTML"],
        },
        {
          title: "CV v2",
          description: "My portfolio website version 2",
          image: "cv.jpg",
          btnProject: "Show",
          link: "https://cv2.decodess.com/",
          language: ["Vue.js", "Javascript", "CSS", "Tailwind", "HTML"],
        },
        {
          title: "CV v3",
          description: "My portfolio website version 3",
          image: "cv.jpg",
          btnProject: "Show",
          link: "https://cv3.decodess.com/",
          language: ["React.js", "Javascript", "CSS", "Tailwind", "HTML"],
        },
        {
          title: "Saloka",
          // description: "Portfolio project to apply to Saloka Theme Park",
          image: "saloka.jpg",
          btnProject: "Show",
          link: "https://saloka.decodess.com/",
          language: [
            "Vue.js",
            "Javascript",
            "CSS",
            "Tailwind",
            "HTML",
            "Laravel",
            "Mysql",
          ],
        },
        {
          title: "Maiga",
          description:
            "Website for selecting outstanding students at the MA (Madrasah Aliyah) Al-Irsyad Gajah school",
          image: "maiga.jpg",
          btnProject: "Show",
          link: "https://maiga.decodess.com/",
          language: ["Codeigniter", "Javascript", "CSS", "HTML", "MySql"],
        },
        {
          title: "SPK",
          description:
            "Decision Support System Website for Selecting Beneficiaries of the Family Hope Program in Wanamulya Village Using SMART (Simple Multi-Attribute Rating Technique) Algorithm",
          image: "spk.jpg",
          btnProject: "Show",
          link: "https://spk.decodess.com/",
          language: ["Codeigniter", "Javascript", "CSS", "HTML", "MySql"],
        },
        {
          title: "Titik Kumpul Accessories",
          description:
            "This is a freelance project aimed at making it easier for customers to order or check the availability of gadget accessories. The website is designed to provide a convenient and efficient shopping experience, with easy-to-use search and ordering features. This platform helps customers quickly and practically find quality gadget accessories.",
          image: "acc.png",
          btnProject: "Show",
          link: "https://titikkumpul.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "Alfi Food & Catering",
          description:
            "This website serves as a demo platform for showcasing food and catering services, designed to help market and promote culinary offerings. It’s a freelance project aimed at highlighting the brand’s expertise and offerings in the food industry.",

          image: "wait.jpg",
          btnProject: "Show",
          link: "https://catering.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "Dinamic CRUD Website",
          description:
            "This is a personal website created to fill free time and explore web development. The primary goal of the website is to provide an easy-to-use platform for managing and creating CRUD (Create, Read, Update, Delete) applications without the need to rewrite code repeatedly. It features dynamic forms that automatically adjust and adapt based on user input, allowing for a seamless and efficient user experience in building web applications.",

          image: "wait.jpg",
          btnProject: "Show",
          link: "https://dinamic.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        ,
        {
          title: "My Documentation Website",
          description:
            "This is a website documenting various programming languages in my own version.",
          image: "wait.jpg",
          btnProject: "Maintenance",
          // link: "https://dinamic.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "Project",
          // description: "Description of Project",
          image: "wait.jpg",
          btnProject: "Coming soon",
          // link: "https://link-to-project3.com",
          language: "",
        },
      ],
      btnProject: "Coming soon",
      btnProject1: "Show",
      // END PROJECT

      // CONTACT
      myContact: "Contact me",
      namePlaceholder: "Your Name",
      emailPlaceholder: "Your Email",
      messagePlaceholder: "Your Message",
      sendButton: "Send",
      // END CONTACT
    },
  },
  id: {
    translation: {
      // NAV
      homenav: "Rumah",
      skillsnav: "Keahlian",
      experiencenav: "Pengalaman",
      projectsnav: "Proyek",
      contactnav: "Kontak",
      docsnav: "Dokumentasi",
      // END NAV

      // HOME
      welcome: "Selamat datang di portofolio saya",
      showcase:
        "Ini adalah tampilan perjalanan, pengalaman, dan minat saya dalam teknologi dan pengembangan. Melalui situs web ini, saya bertujuan untuk menyoroti keterampilan, keahlian, dan proyek yang saya banggakan.",
      whoAmI: "Siapa Saya?",
      about:
        "Saya adalah seorang programmer yang berdedikasi dan antusias dengan <strong>3 tahun pengalaman</strong>. Dengan hasrat untuk menciptakan solusi digital yang inovatif, saya mengkhususkan diri dalam <strong>pengembangan web</strong>, <strong>sistem backend</strong>, dan <strong>desain responsif</strong>. Tujuan saya adalah memadukan kreativitas dan fungsionalitas, menyampaikan proyek yang meningkatkan baik <strong>pengalaman pengguna</strong> dan kinerja teknis.",
      skills: "Keahlian & Kompetensi Saya",
      skillsList: {
        frontend:
          "Pengembangan Frontend: Antarmuka modern, responsif, dan interaktif.",
        backend:
          "Pengembangan Backend: Logika sisi server yang efisien dan aman.",
        design:
          "Desain Responsif: Pengalaman pengguna yang mulus di semua perangkat.",
        security:
          "Otentikasi & Keamanan: Kontrol akses pengguna dan perlindungan data.",
      },
      whatYoullFind: "Apa yang Akan Anda Temukan Di Sini",
      projectsDescription:
        "Jelajahi proyek yang telah saya kerjakan—apakah itu <strong>website dinamis</strong>, <strong>sistem berbasis database</strong>, atau <strong>aplikasi web kustom</strong>. Saya percaya pada pertumbuhan dan pembelajaran yang berkelanjutan, dan portofolio saya mencerminkan perjalanan penemuan yang terus berkembang.",
      viewProjects: "Lihat Proyek Saya",
      // END HOME

      // KEAHLIAN
      mySkills: "Keahlian Saya",
      skillCategories: {
        "Programming Languages": "Bahasa Pemrograman",
        "Frontend Development": "Pengembangan Frontend",
        "Backend Development": "Pengembangan Backend",
        Databases: "Basis Data",
        "Version Control": "Kontrol Versi",
        "API Development": "Pengembangan API",
        "Tools & Technologies": "Alat & Teknologi",
        "Other Skills": "Keahlian Lain",
        "Mobile Development": "Pengembangan Mobile",
      },
      // END KEAHLIAN

      // EXPERIENCE
      myExperience: "Pengalaman Saya",
      experienceTitles: {
        title_exp1: "IT Support di Koperasi UMKM, Jawa Tengah",
        title_exp2: "IT Programmer di Macanan Jaya Cemerlang",
        title_exp3: "Bootcamp Software Engineer di Formulatrix Indonesia",
        title_exp4:
          "IT Programmer & Business Operations Excelence di Cikarang Inland Port",
        title_exp5: "Programmer - Freelance",
      },
      experienceStatuses: {
        internship: "Magang",
        apprentice: "Praktik Kerja",
        contract: "Kontrak",
        fulltime: "Penuh Waktu",
        freelance: "Freelance",
      },
      experienceDurations: {
        duration_exp1: "Agustus 2019 - September 2019",
        duration_exp2: "Maret 2022 - Juni 2023",
        duration_exp3: "November 2023 - Januari 2024",
        duration_exp4: "April 2024 - Sekarang",
        duration_exp5: "Maret 2021 - Sekarang",
      },
      experienceDescriptions: {
        desc_exp1:
          "Kolaborasi dengan tim IT untuk membangun dan meningkatkan situs web, serta membuat desain 2D.",
        desc_exp2:
          "Kolaborasi dengan tim IT untuk:\n* Mengembangkan dan memelihara berbagai sistem (pemasaran, pembelian, manajemen gudang, keuangan) menggunakan Visual Basic/VB6 & Crystal Report.\n* Membuat situs web operasional menggunakan CodeIgniter, Vue.js, dan Laravel.\n* Mengembangkan dan memelihara aplikasi produksi menggunakan FoxPro.\n* Mengelola dan memelihara database menggunakan SQL Server.",
        desc_exp3:
          "Belajar program pendidikan intensif yang fokus pada bahasa pemrograman C#, Pemrograman Berorientasi Objek, framework ASP.NET Core 7, logger, Unit testing, dan pola desain.",
        desc_exp4:
          "- IT BOE (IT Business Operation Excellence)\nSebagai IT BOE, saya fokus untuk memastikan kelancaran operasional bisnis dengan mendukung pengguna ketika masalah muncul dan menganalisis sistem serta data.\nSaya bekerja untuk mengidentifikasi dan menyelesaikan hambatan, mengoptimalkan alur kerja, dan menerapkan pembaruan sistem untuk meningkatkan efisiensi.\n\n- IT Programmer\nSebagai IT Programmer, saya membangun dan memelihara situs web kustom menggunakan CodeIgniter & SQL Server.\nSaya fokus pada pengembangan fitur baru, perbaikan masalah, dan pengoptimalan sistem untuk memastikan kelancaran operasi.\nSaya mengelola solusi situs web untuk tiga perusahaan: Cikarang Dryport (CDP), Cikarang Gerbang Solusi (CGS), dan Gerbang Teknologi Cikarang (GTC), memastikan mereka memenuhi kebutuhan bisnis spesifik.\n\nSaya telah mengelola sistem seperti:\n* DOSS CIP & CGS (Sistem One Stop Service DryPort)\n* Sistem Gudang GTC & CGS (Manajemen Inventaris dan Gudang)\n* Sistem Autogate (Sistem Gerbang Otomatis untuk kendaraan)\n* MyCDP (Sistem Manajemen Pesanan)\n* Aplikasi Portal Vendor (Portal Manajemen Vendor)\n\nSaya juga telah membangun dari nol:\n* Website Monitoring E-Document Order (Pelacakan pesanan dan dokumen online)\n* Website Booking Vehicle (Sistem pemesanan dan penjadwalan kendaraan)\n\nSelain itu, saya juga berkolaborasi dengan Bea Cukai untuk menghubungkan dan mengintegrasikan sistem, memastikan pertukaran data yang lancar.",
        desc_exp5:
          "Sebagai freelance developer, saya membuat situs web dan aplikasi kustom berdasarkan kebutuhan klien, dengan fokus kuat pada antarmuka pengguna yang intuitif dan pengalaman pengguna yang lancar.",
      },
      // END EXPERIENCE

      // PROJEK
      myProject: "Proyek Saya",
      projects: [
        {
          title: "CV v1",
          description: "Portofolio website versi 1",
          image: "cv.jpg",
          btnProject: "Lihat",
          link: "https://cv1.decodess.com/",
          language: ["Vue.js", "Javascript", "CSS", "Tailwind", "HTML"],
        },
        {
          title: "CV v2",
          description: "Portofolio website versi 2",
          image: "cv.jpg",
          btnProject: "Lihat",
          link: "https://cv2.decodess.com/",
          language: ["Vue.js", "Javascript", "CSS", "Tailwind", "HTML"],
        },
        {
          title: "CV v3",
          description: "Portofolio website versi 3",
          image: "cv.jpg",
          btnProject: "Lihat",
          link: "https://cv3.decodess.com/",
          language: ["React.js", "Javascript", "CSS", "Tailwind", "HTML"],
        },
        {
          title: "Saloka",
          // description: "Proyek portofolio untuk mendaftar ke saloka theme park",
          image: "saloka.jpg",
          btnProject: "Lihat",
          link: "https://saloka.decodess.com/",
          language: [
            "Vue.js",
            "Javascript",
            "CSS",
            "Tailwind",
            "HTML",
            "Laravel",
            "Mysql",
          ],
        },
        {
          title: "Maiga",
          description:
            "Website sistem pemilihan siswa berprestasi MA Al-Irsyad gajah",
          image: "maiga.jpg",
          btnProject: "Lihat",
          link: "https://maiga.decodess.com/",
          language: ["Codeigniter", "Javascript", "CSS", "HTML", "MySql"],
        },
        {
          title: "SPK",
          description:
            "Website sistem pendukung keputusan pemilihan warga penerima program keluarga harapan desa wanamulya dengan algoritma SMART (Simple Multi-Attribute Rating Technique)",
          image: "spk.jpg",
          btnProject: "Lihat",
          link: "https://spk.decodess.com/",
          language: ["Codeigniter", "Javascript", "CSS", "HTML", "MySql"],
        },
        {
          title: "Alfi Food & Catering",
          description:
            "Website ini berfungsi sebagai platform demo untuk menampilkan layanan makanan dan katering, yang dirancang untuk membantu memasarkan dan mempromosikan penawaran kuliner. Ini adalah proyek freelance yang bertujuan untuk menyoroti keahlian dan penawaran merek dalam industri makanan.",
          image: "catering.png",
          btnProject: "Lihat",
          link: "https://catering.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "Titik Kumpul Accessories",
          description:
            "Ini adalah proyek freelance yang bertujuan untuk memudahkan pelanggan dalam memesan atau mencari ketersediaan aksesoris gadget. Website ini dirancang untuk memberikan pengalaman belanja yang nyaman dan efisien, dengan fitur pencarian dan pemesanan yang mudah digunakan. Platform ini membantu pelanggan menemukan aksesoris gadget berkualitas dengan cepat dan praktis.",

          image: "wait.jpg",
          btnProject: "Lihat",
          link: "https://titikkumpul.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "Dinamic CRUD Website",
          description:
            "Ini adalah website pribadi yang dibuat untuk mengisi waktu luang dan mengeksplorasi pengembangan web. Tujuan utama dari website ini adalah untuk menyediakan platform yang mudah digunakan dalam mengelola dan membuat aplikasi CRUD (Create, Read, Update, Delete) tanpa perlu menulis ulang kode. Website ini dilengkapi dengan fitur formulir dinamis yang otomatis menyesuaikan dan beradaptasi sesuai dengan input pengguna, memberikan pengalaman pengguna yang lancar dan efisien dalam membangun aplikasi web.",

          image: "wait.jpg",
          btnProject: "Lihat",
          link: "https://dinamic.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "My Documentation Website",
          description:
            "Website dokumentasi berbagai macam bahasa pemrograman versi saya.",

          image: "wait.jpg",
          btnProject: "Maintenance",
          // link: "https://dinamic.decodess.com/",
          language: [
            "React.js",
            "Javascript",
            "Laravel",
            "CSS",
            "Tailwind",
            "HTML",
            "MySql",
          ],
        },
        {
          title: "Proyek",
          // description: "Deskripsi Proyek",
          image: "wait.jpg",
          btnProject: "Segera hadir",
          // link: "https://link-to-project3.com",
        },
      ],
      // END PROJEK

      // KONTAK
      myContact: "Kontak Saya",
      namePlaceholder: "Nama Anda",
      emailPlaceholder: "Email Anda",
      messagePlaceholder: "Pesan Anda",
      sendButton: "Kirim",
      // END KONTAK
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("i18nextLng", lng);
});

export default i18n;
